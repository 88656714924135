*,
*:before,
*::after {
    box-sizing: border-box;
}

body {
    --white: #ffffff;
    --background: #fafafa;
    --text: #222222;
    --text-light: #808080;
    --box-background: #ffffff;
    --grey: #d9d9d9;
    --light-grey: #b6b6b6;
    --orange: #ff9f45;
    --green: #009379;
    --blue: #3366ff;
    --red: #ff6250;
    --yellow: #ffdf90;
    --purple: #bfaff2;
    --teal: #008c99;
    --cyan: #00c4cc;
    --pink: #ff7eb9;
    --brown: #a05a2c;
    --lime: #b4d400;
    --indigo: #4b0082;
    --brand1: var(--orange);

    --radius: 8px;
    margin: 0 auto;
    padding: 0;
    background-color: var(--background);
    color: var(--text);
    font-family: polymath, sans-serif;
    font-size: 14px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: var(--text);
    padding-right: 2px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@import '../../styles/global.scss';
@import '../../styles/breakpoints.scss';
@import url('https://use.typekit.net/pbx7uun.css');

:root {
    --background: #fafafa;
    --text: #222222;
    --box-background: #ffffff;
    --grey: #d9d9d9;
    --light-grey: #b6b6b6;

    --orange: #ff9f45;
    --green: #009379;
    --blue: #3366ff;
    --red: #ff6250;
    --yellow: #ffdf90;
    --purple: #bfaff2;
    --teal: #008c99;
    --cyan: #00c4cc;
    --pink: #ff7eb9;
    --brown: #a05a2c;
    --lime: #b4d400;
    --indigo: #4b0082;

    --brand1: var(--orange);
    --radius: 8px;
}

.mainWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 116px auto 16px;
    width: 100%;

    @include md {
        margin: 80px auto 32px;
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
}

.current {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 0 var(--radius);
    padding: 4px 10px;
    background-color: var(--brand1);
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: var(--white);
}

.header {
    margin: 0;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.33;
    text-align: left;

    strong {
        font-weight: 800;
    }
}

.benefits {
    margin: 0 0 24px;
    text-align: left;
    color: var(--text-light);

    p {
        margin: 0;
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 6px;
    margin: 0 auto;
}

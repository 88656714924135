.wrapper {
    position: relative;
    width: max-content;
    margin: 0;
    cursor: pointer;
}

.portal {
    z-index: 9000;
    position: fixed;
    top: 0;
    left: 0;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: none;
    padding: 0;
    background: none;
}

.box {
    z-index: 3000;
    position: absolute;
    box-shadow:
        rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: var(--radius);
    width: max-content;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition:
        opacity 0.3s ease,
        transform 0.3s ease;
    will-change: transform, opacity;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bottomLeft {
    margin: 6px 0 0;
    top: 100%;
    left: 0;
}

.bottomCenter {
    margin: 6px 0 0;
    top: 100%;
    left: 50%;
}

.bottomRight {
    margin: 6px 0 0;
    top: 100%;
    right: 0;
}

.topLeft {
    margin: 0 0 6px;
    bottom: 100%;
    left: 0;
}

.topCenter {
    margin: 0 0 6px;
    bottom: 100%;
    left: 50%;
}

.topRight {
    margin: 0 0 6px;
    bottom: 100%;
    right: 0;
}

.box[data-headlessui-state='open'] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}
